import { extendTheme } from "@chakra-ui/react"
const theme = {
  colors: {
    primary: {
      main: "teal.500",
    },
    secondary: {
      main: "cyan.200",
    }
  },
  styles: {
    global: {
      ul: {
      },
      a: {
        color: "#6594e0",
        textDecoration: `underline #6594e0 dotted 4px`,
        textUnderlineOffset: `0.2em`,
        _hover: {
          color: "#6594e0",
          textDecoration: `#6594e0 dotted 4px`,
          textUnderlineOffset: `0.5em`,
        },
      },
      h1: {
        marginTop: `1.2em`,
        position: `relative`,
        padding: `0.5em`,
        background: `#a6d3c8`,
        color: `white`,
        fontSize: `x-large`,
        _before: {
          position: `absolute`,
          content: `""`,
          top: `100%`,
          left: 0,
          border: `none`,
          borderBottom: `solid 15px transparent`,
          borderRight: `solid 20px rgb(149, 158, 155)`,
        }
      },
    },
  },
}

export default extendTheme(theme)
